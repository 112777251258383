<template>
  <div>
    <v-dialog v-model="dialog.dialogProgress" persistent width="300" eager>
      <v-card color="primary" dark>
        <v-card-text>
          {{progressMessage}}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-data-table
      v-model="vDataTable.selected"
      :headers="headers"
      :items="vDataTable.data"
      :search="vDataTable.search"
      show-select
      fixed-header
      :expanded.sync="vDataTable.expanded"
      show-expand
      :page.sync="vDataTable.options.page"
      :itemsPerPage.sync="vDataTable.options.itemsPerPage"
      :options.sync="vDataTable.options"
      :height="$vuetify.breakpoint.height - 123 - offsetAppBar"
      :footer-props="{
        itemsPerPageText: $t('itemsPerPageText'),
        itemsPerPageAllText: $t('itemsPerPageAllText'),
        showFirstLastPage: true,
        showCurrentPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
      }"
      item-key="skey"
      class="fill-height">
      <template v-slot:header.data-table-select="{ on, props }">
        <tr>
          <td>
            <v-simple-checkbox :ripple="false" v-bind="props" v-on="on"></v-simple-checkbox>
          </td>
          <td v-if="vDataTable.selected.length > 0">
            {{vDataTable.selected.length}}
          </td>
        </tr>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{$t('DocumentHeaderMaint.DocumentHeaderTitle')}}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog.dialogAddEdit" fullscreen hide-overlay eager>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="primary" v-bind="attrs" v-on="on" style="text-transform: capitalize" @click="newItem()">
                <img src="../../assets/SoftwareClinicIcon/New.png" height="30" weight="30"/>{{$t('DocumentHeaderMaint.buttonnewDocumentHeader')}}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <!-- <v-col cols="12" sm="12" md="12">
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <img v-bind:src="utils.imageWait(vDataTable.editedItem.picture_base64)" width="100%" height="100%">
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <input type="file" style="display: none" ref="imageInsert" accept="image/*" @change="handleFileSelectInsert">
                          <v-btn class="text-right" outlined color="primary" style="text-transform: capitalize" @click="pickFileInsert">
                            {{$t('DocumentHeaderMaint.AddPicture')}}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col> -->
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                         outlined dense hide-details
                        :label="$t('DocumentHeaderMaint.DocumentHeaderName')"
                        v-model="vDataTable.editedItem.name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea class="ma-0 pa-0" hide-details
                        outlined
                        name="input-7-4"
                        :label="$t('DocumentHeaderMaint.remark')"
                        v-model="vDataTable.editedItem.remark"
                        rows="5"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="2" class="mt-0 pa-0">
                      <!-- <v-checkbox label="Foo" input-value="1"></v-checkbox> -->
                      <v-checkbox outlined dense hide-details
                        false-value="N"
                        true-value="Y"
                        ref="checkBoxIHCSpecialStaining"
                        v-model="vDataTable.editedItem.active_flag">
                        <template v-slot:label>
                          <div><span class='text--primary'>Active</span></div>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                  <v-btn outlined color="success" @click="save">{{$t('save')}}</v-btn>
                  <v-btn outlined color="error" @click="close">{{$t('cancel')}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-divider class="mx-1" inset vertical></v-divider>
          <v-btn outlined color="primary" @click="checkDeleteselected" style="text-transform: capitalize">
            <img src="../../assets/SoftwareClinicIcon/Delete.png" height="30" weight="30"/>{{$t('DocumentHeaderMaint.buttonDeleteDocumentHeader')}}
          </v-btn>
          <v-divider class="mx-1" inset vertical></v-divider>
          <v-btn outlined color="primary" @click.native="expandCollapseNode(true)" style="text-transform: capitalize">Expand</v-btn>
          <v-divider class="mx-1" inset vertical></v-divider>
          <v-btn outlined color="primary" @click.native="expandCollapseNode(false)" style="text-transform: capitalize">Collapse</v-btn>
          <v-divider class="mx-1" inset vertical></v-divider>
          <v-btn outlined color="primary" style="text-transform: capitalize" @click="retrieveDocumentHeader(appName)">
            <img src="../../assets/SoftwareClinicIcon/Find.png" height="30" weight="30"/>{{$t('find')}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field outlined dense hide-details v-model="vDataTable.search" append-icon="mdi-magnify" :label="$t('search')"></v-text-field>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.data-table-select="{ isSelected, select }">
        <v-simple-checkbox :value="isSelected" :ripple="false" @input="select($event)" ></v-simple-checkbox>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="teal" small class="mr-2" v-bind="attrs" v-on="on" @click="editItem(item)">edit</v-icon>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="pink" small class="mr-2" v-bind="attrs" v-on="on" @click="deleteItemConfirm(item)">delete</v-icon>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
      <template v-slot:item.data-table-no="{ item, index }">
        {{((vDataTable.options.page - 1) * vDataTable.options.itemsPerPage) + index + 1}}
      </template>
      <template v-slot:no-data>
        <v-alert type="info" border="bottom" colored-border elevation="2">
          {{$t('noData')}}
        </v-alert>
      </template>
      <template v-slot:no-results>
        <v-alert type="warning">
          {{stringFormat($t('searchNotFound'),vDataTable.search)}}
        </v-alert>
      </template>
      <template v-slot:footer>
        <tr class="sticky-table-footer" style="position: absolute">
          <td>Selected({{vDataTable.selected.length}})s</td> 
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }"> 
        <td :colspan="headers.length">
          <v-card class="elevation-10">
            <v-card-text>
              <v-data-table v-model="item.selectedLanguage" 
                :headers="subHeadersLanguage"
                :items="item.mtmn_document_header_language"
                :search="item.searchLanguage"
                show-select
                :footer-props="{
                  itemsPerPageText: $t('itemsPerPageText'),
                  itemsPerPageAllText: $t('itemsPerPageAllText'),
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-minus',
                  nextIcon: 'mdi-plus',
                }"
                item-key="uid"
                class="elevation-1">
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Language</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog.dialogLanguage" fullscreen hide-overlay eager>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="primary" v-bind="attrs" v-on="on" style="text-transform: capitalize" @click="newDocumentHeaderLanguage(item)">
                          <img src="../../assets/SoftwareClinicIcon/New.png" height="30" weight="30"/>{{$t('DocumentHeaderMaint.buttonnewDocumentHeaderLanguage')}}
                        </v-btn>        
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">{{ formLanguageTitle }}</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="12" md="12">  
                                <v-autocomplete
                                  outlined dense hide-details
                                  :items="languages"
                                  item-value="language_skey"
                                  item-text="language_comment"
                                  v-model="vDataTable.editedLanguageItem.language_skey"
                                  :label="$t('language')"
                                  :readonly="languageEdit">
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  outlined dense hide-details
                                  :label="$t('DocumentHeaderMaint.DocumentHeaderName')" v-model="vDataTable.editedLanguageItem.name"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="12">
                                <vue-editor
                                  v-model="vDataTable.editedLanguageItem.remark"
                                  :id="vueEditorOrganGross"
                                  :editor-toolbar="customToolbar"
                                  :editorOptions="editorSettings"/>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                            <!-- <v-btn outlined color="primary" @click="copyHeader()">{{$t('DocumentHeaderMaint.copyHeader')}}</v-btn> -->
                            <v-btn outlined color="success" @click="saveLanguage">{{$t('save')}}</v-btn>
                            <v-btn outlined color="error" @click="closeLanguage">{{$t('cancel')}}</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-divider class="mx-1" inset vertical></v-divider>
                    <v-btn outlined color="primary" @click="checkDeleteselectedLanguage(item)" style="text-transform: capitalize">
                      <img src="../../assets/SoftwareClinicIcon/Delete.png" height="30" weight="30"/>{{$t('DocumentHeaderMaint.buttonDeleteDocumentHeaderLanguage')}}
                    </v-btn>
                    <v-divider class="mx-1" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="item.searchLanguage" append-icon="mdi-magnify" :label="$t('search')"></v-text-field>
                  </v-toolbar>
                </template>
                <template v-slot:item.path_image="{ item }">
                  <img v-bind:src="utils.imageWait(item.picture_base64)" width="100%" height="100%">
                </template>
                <!-- <template v-slot:item.picture_base64="{ item }">
                  <img v-bind:src="utils.imageWait(item.picture_base64)" width="100%" height="100%">
                </template> -->
                <img v-bind:src="utils.imageWait(vDataTable.editedItem.picture_base64)" width="100%" height="100%">
                <template v-slot:item.actions="{ item }">
                  <v-icon color="teal" small class="mr-2" @click="editLanguageItem(item)">edit</v-icon>
                  <v-icon color="pink" small class="mr-2" @click="deleteLanguageConfirm(item)">delete</v-icon>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </td>
      </template>
    </v-data-table> 
  </div>
</template>
<script>
import { mapMutations, mapGetters, mapState } from 'vuex'
import Base64Binary from 'base64-arraybuffer'
import { VueEditor, Quill } from 'vue2-editor'
/* import { consoleLog } from '../../../node_modules-/echarts/lib/util/log' */
var Keyboard = Quill.import('modules/keyboard')
class CustomKeyboard extends Keyboard {
  static DEFAULTS = {
    ...Keyboard.DEFAULTS,
    bindings: {
      ...Keyboard.DEFAULTS.bindings,
      ['list autofill']: undefined,
    }
  }
}
Quill.register('modules/keyboard', CustomKeyboard)
export default {
  components: {
    VueEditor,
  },

  props: ['parentComponent'],

  data () {
    return {
      utils: require('@/utils'),
      stringFormat: require('string-format'),
      appName: process.env.VUE_APP_DB_APP_NAME,
      rules: {
        winName: value => {
          const pattern = /^[a-zA-Z0-9$@$!%*?&#^-_. +]+$/
          return pattern.test(value) || 'Invalid Window Name'
        },
      },
      dialog: {
        dialogProgress: false,
        dialogAddEdit: false,
        dialogLanguage: false,
      },
      vDataTable: {
        search: '',
        selected: [],
        data: [],
        editedIndex: -1,
        editedItem: {
          win_name: '',
          window_comment: ''
        },
        defaultItem: {
          win_name: '',
          window_comment: ''
        },
        editedLanguageIndex: -1,
        editedLanguageItem: {
        },
        defaultLanguageItem: {
        },
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: true,
          multiSort: true,
          // mustSort: true
        },
        expanded: [],
      },
      progressMessage: '',
      languages: [],
      languageEdit: true,
      vueEditorOrganGross: 'vue-editorOrganGrossCustomHeight200',
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'],
        /* [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }], */
        /* [{ list: 'ordered' }, { list: 'check' }], */
        /* [{ indent: '-1' }, { indent: '+1' }], */
        [{ color: [] }],
        ['clean']
      ],
      editorSettings: {
        fomats: [
          'background',
          'bold',
          'color',
          'font',
          'code',
          'italic',
          'link',
          'size',
          'strike',
          'script',
          'underline',
          'blockquote',
          'header',
          'indent',
          'align',
          'direction',
          'code-block',
          'formula',
          'image',
          'video'
        ],
      },
    }
  },

  computed: {
    ...mapGetters(['getShowAppBar', 'getAppBarHeight']),
    formTitle () {
      return this.vDataTable.editedIndex === -1 ? this.$t('DocumentHeaderMaint.newDocumentHeader') : this.$t('DocumentHeaderMaint.newDocumentHeader')
    },
    formLanguageTitle () {
      return this.vDataTable.editedLanguageIndex === -1 ? this.$t('DocumentHeaderMaint.newDocumentHeaderLanguage') : this.$t('DocumentHeaderMaint.editDocumentHeaderLanguage')
    },
    headers () {
      return [
        { text: '#', align: 'center', value: 'data-table-no' },
        { text: '', align: 'center', value: 'data-table-select' },
        { text: 'skey', align: 'left', value: 'skey', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('actions'), value: 'actions', sortable: false, class: 'text-xl-left body-1 black--text' },
        { text: this.$t('DocumentHeaderMaint.DocumentHeaderName'), value: 'name', align: 'left', class: 'text-xl-left body-1 black--text' },
        /* { text: 'Image', value: 'path_image', sortable: false, class: 'text-xl-left body-1 black--text' }, */
        { text: this.$t('createUser'), value: 'create_user_id', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('createDate'), value: 'create_date', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('maintUser'), value: 'maint_user_id', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('maintDate'), value: 'maint_date', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: 'Active', value: 'active_flag', align: 'left', class: 'text-xl-left body-1 black--text' },
      ]
    },
    subHeaders () {
      return [
        { text: this.$t('navWindow.windowName'), value: 'win_name' },
        { text: this.$t('createUser'), value: 'create_user_id' },
      ]
    },
    subHeadersLanguage () {
      return [
        { text: '', align: 'center', value: 'data-table-select' },
        { text: this.$t('actions'), value: 'actions', sortable: false },
        { text: this.$t('language'), value: 'language_comment' },
        { text: this.$t('DocumentHeaderMaint.DocumentHeaderName'), value: 'name', align: 'left' },
      ]
    },
    offsetAppBar () {
      // return this.getShowAppBar ? 0 : this.getAppBarHeight
      return this.getShowAppBar ? this.getAppBarHeight : 0
    },
  },

  watch: {
    'dialog.dialogAddEdit' (val) {
      val || this.close()
    }
  },

  async created () {
    this.retrieveLanguage()
    await this.retrieveDocumentHeader(this.appName)
  },

  mounted () {
  },

  updated () {
  },

  methods: {
    /* getIp () {
      console.log('getip')
      this.axios.post('https://api.db-ip.com/v2/free/self', {
      })
        .then(response => {
          console.log(response, 'response')
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    }, */
    selectLanguage (language) {
    },
    initialize () {
      this.vDataTable.selected = []
      this.vDataTable.editedIndex = -1
      this.vDataTable.editedItem = {
        win_name: '',
        window_comment: ''
      }
      this.vDataTable.defaultItem = {
        win_name: '',
        window_comment: ''
      }
    },
    newDocumentHeaderLanguage (item) {
      this.vDataTable.editedItem = Object.assign({}, item)
      // this.dialogLanguage = true
      this.languageEdit = false
    },
    expandCollapseNode (expand) {
      if (expand) {
        this.vDataTable.expanded = this.vDataTable.data
      } else {
        this.vDataTable.expanded = []
      }
    },
    findCopyHeader (skey) {
      return this.vDataTable.data.find(function (x) {
        return (x.skey === skey)
      })
    },
    findLanguage (skey) {
      return this.vDataTable.data.find(function (x) {
        return (x.skey === skey)
      }).mtmn_document_header_language
    },
    findNodeSelectedLanguage (skey) {
      return this.vDataTable.data.find(function (x) {
        return (x.skey === skey)
      }).selectedLanguage
    },
    retrieveLanguage () {
      this.axios.post(process.env.VUE_APP_API + '/document_header/retrieveLanguage' + process.env.VUE_APP_DATABASE_TYPE, {
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.languages = response.data.data
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async retrieveDocumentHeader (appName) {
      console.log('retrieveDocumentHeader')
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')
      await this.axios.post(process.env.VUE_APP_API + '/document_header/retrieveDocumentHeader' + process.env.VUE_APP_DATABASE_TYPE, {
        app_name: appName
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.vDataTable.data = response.data.data
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
      this.dialog.dialogProgress = false
    },
    async addDocumentHeader (name, remark, filename, pictureBase64, activeFlag) {
      if (!filename) {
        filename = ''
      }
      if (!pictureBase64) {
        pictureBase64 = ''
      }
      if (!remark) {
        remark = ''
      }
      await this.axios.post(process.env.VUE_APP_API + '/document_header/addDocumentHeader' + process.env.VUE_APP_DATABASE_TYPE, {
        name: name,
        remark: remark,
        filename: filename,
        picture_base64: pictureBase64,
        active_flag: activeFlag
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.vDataTable.editedItem.skey = response.data.data.skey
            this.vDataTable.data.push(this.vDataTable.editedItem)
            this.retrieveDocumentHeader()
            this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async updateDocumentHeader (skey, name, remark, filename, pictureBase64, activeFlag) {
      if (!filename) {
        filename = ''
      }
      if (!pictureBase64) {
        pictureBase64 = ''
      }
      if (!remark) {
        remark = ''
      }
      await this.axios.post(process.env.VUE_APP_API + '/document_header/updateDocumentHeader' + process.env.VUE_APP_DATABASE_TYPE, {
        skey: skey,
        name: name,
        remark: remark,
        filename: filename,
        picture_base64: pictureBase64,
        active_flag: activeFlag,
      })
        .then(response => {
          if (response.data.isSuccess) {
            Object.assign(this.vDataTable.data[this.vDataTable.editedIndex], this.vDataTable.editedItem)
            this.retrieveDocumentHeader()
            this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async addDocumentHeaderLanguage (skey, languageSkey, name, remark) {
      if (!remark) {
        remark = ''
      }
      await this.axios.post(process.env.VUE_APP_API + '/document_header/addDocumentHeaderLanguage' + process.env.VUE_APP_DATABASE_TYPE, {
        skey: skey,
        language_skey: languageSkey,
        name: name,
        remark: remark,
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.vDataTable.editedLanguageItem.skey = response.data.data.skey
            this.vDataTable.editedLanguageItem.language_comment = this.languages.find(function (x) {
              return (x.language_skey === languageSkey)
            }).language_comment
            this.findLanguage(skey).push(this.vDataTable.editedLanguageItem)
            this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async updateDocumentHeaderLanguage (skey, languageSkey, name, remark) {
      if (!remark) {
        remark = ''
      }
      await this.axios.post(process.env.VUE_APP_API + '/document_header/updateDocumentHeaderLanguage' + process.env.VUE_APP_DATABASE_TYPE, {
        skey: skey,
        language_skey: languageSkey,
        name: name,
        remark: remark,
      })
        .then(response => {
          if (response.data.isSuccess) {
            Object.assign(this.findLanguage(skey)[this.vDataTable.editedLanguageIndex], this.vDataTable.editedLanguageItem)
            this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    checkDeleteselected () {
      if (this.vDataTable.selected.length > 0) {
        this.$swal({
          text: this.$t('DocumentHeaderMaint.confirmDeleteSelected'),
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no')
        }).then((result) => {
          if (result) {
            this.deleteSelected()
          }
        })
      } else {
        this.$swal({type: 'warning', title: this.$t('DocumentHeaderMaint.messageDeleteNotSelected')})
      }
    },
    checkDeleteselectedLanguage (item) {
      if (this.findNodeSelectedLanguage(item.skey).length > 0) {
        this.$swal({
          text: this.$t('DocumentHeaderMaint.confirmDocumentHeaderLanguageDeleteSelected'),
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no')
        }).then((result) => {
          if (result) {
            this.deleteLanguageSelected(item)
          }
        })
      } else {
        this.$swal({type: 'warning', title: this.$t('DocumentHeaderMaint.messagedeleteDocumentHeaderLanguageNotSelected')})
      }
    },
    async deleteSelected () {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')

      var del = []

      await this.utils.asyncForEach(this.vDataTable.selected, async function (x) {
        this.progressMessage = this.stringFormat(this.$t('DocumentHeaderMaint.progressMessageDelete'), x.name_name)
        var val = await this.deleteNode(x)

        if (val !== undefined) {
          del.push(val)
        }
      }.bind(this))
      for (const x in del) {
        this.vDataTable.selected.splice(this.vDataTable.selected.indexOf(x), 1)
      }

      this.dialog.dialogProgress = false
    },
    async deleteLanguageSelected (item) {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')

      var del = []

      await this.utils.asyncForEach(this.findNodeSelectedLanguage(item.skey), async function (x) {
        this.progressMessage = this.stringFormat(this.$t('DocumentHeaderMaint.progressMessageNodeLanguageDelete'), x.name)
        var val = await this.deleteLanguage(x)

        if (val !== undefined) {
          del.push(val)
        }
      }.bind(this))
      for (const x in del) {
        var selectedLanguage = this.findNodeSelectedLanguage(item.skey)
        selectedLanguage.splice(selectedLanguage.indexOf(x), 1)
      }

      this.dialog.dialogProgress = false
    },
    async deleteNode (item) {
      try {
        this.dialog.dialogProgress = true
        this.progressMessage = this.$t('waiting')
        var skey = item.skey
        var valRet
        await this.axios.post(process.env.VUE_APP_API + '/document_header/deleteDocumentHeader' + process.env.VUE_APP_DATABASE_TYPE, {
          skey: skey
        })
          .then(async response => {
            if (response.data.isSuccess) {
              if (this.vDataTable.data.indexOf(item) >= 0) {
                this.vDataTable.data.splice(this.vDataTable.data.indexOf(item), 1)
              }
              this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
              valRet = item
            } else {
              await this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(async e => {
            await this.$swal({type: 'error', title: e.message})
          })
        return valRet
      } catch (e) {
        await this.$swal({type: 'error', title: e.message})
      } finally {
        this.dialog.dialogProgress = false
      }
    },
    newItem () {
      this.vDataTable.editedItem.active_flag = 'Y'
    },
    editItem (item) {
      this.vDataTable.editedIndex = this.vDataTable.data.indexOf(item)
      this.vDataTable.editedItem = Object.assign({}, item)
      this.dialog.dialogAddEdit = true
    },
    editLanguageItem (item) {
      this.languageEdit = true
      this.vDataTable.editedLanguageIndex = this.findLanguage(item.skey).indexOf(item)
      this.vDataTable.editedLanguageItem = Object.assign({}, item)
      this.dialog.dialogLanguage = true
    },
    async deleteItemConfirm (item) {
      this.vDataTable.editedIndex = this.vDataTable.data.indexOf(item)
      this.vDataTable.editedItem = Object.assign({}, item)
      this.$swal({
        text: this.stringFormat(this.$t('DocumentHeaderMaint.confirmDelete'), item.name),
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      }).then(async (result) => {
        if (result) {
          var val = await this.deleteNode(item)
          if (val !== undefined) {
            if (this.vDataTable.selected.indexOf(this.vDataTable.editedItem) >= 0) {
              this.vDataTable.selected.splice(this.vDataTable.selected.indexOf(this.vDataTable.editedItem), 1)
            }
          }
          this.vDataTable.editedItem = Object.assign({}, this.vDataTable.defaultItem)
          this.vDataTable.editedIndex = -1
        }
      })
    },
    async deleteLanguage (item) {
      try {
        this.dialog.dialogProgress = true
        this.progressMessage = this.$t('waiting')
        var skey = item.skey
        var languageSkey = item.language_skey
        var valRet
        await this.axios.post(process.env.VUE_APP_API + '/document_header/deleteDocumentHeaderLanguage' + process.env.VUE_APP_DATABASE_TYPE, {
          skey: skey,
          language_skey: languageSkey
        })
          .then(async response => {
            if (response.data.isSuccess) {
              if (this.findLanguage(item.skey).indexOf(item) >= 0) {
                this.findLanguage(item.skey).splice(this.findLanguage(item.skey).indexOf(item), 1)
              }
              this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
              valRet = item
            } else {
              await this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(async e => {
            await this.$swal({type: 'error', title: e.message})
          })
        return valRet
      } catch (e) {
        await this.$swal({type: 'error', title: e.message})
      } finally {
        this.dialog.dialogProgress = false
      }
    },
    async deleteLanguageConfirm (item) {
      this.vDataTable.editedLanguageIndex = this.findLanguage(item.skey).indexOf(item)
      this.vDataTable.editedLanguageItem = Object.assign({}, item)
      this.$swal({
        text: this.stringFormat('Are you sure delete language {} node {}?', item.language_comment, item.name),
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      }).then(async (result) => {
        if (result) {
          var val = await this.deleteLanguage(item)
          if (val !== undefined) {
            var selectedLanguage = this.findNodeSelectedLanguage(item.skey)
            if (selectedLanguage.indexOf(item) >= 0) {
              selectedLanguage.splice(selectedLanguage.indexOf(item), 1)
            }
          }
          this.vDataTable.editedLanguageItem = Object.assign({}, this.vDataTable.defaultItem)
          this.vDataTable.editedLanguageIndex = -1
        }
      })
    },
    close () {
      this.dialog.dialogAddEdit = false
      this.$nextTick(() => {
        this.vDataTable.editedItem = Object.assign({}, this.vDataTable.defaultItem)
        this.vDataTable.editedIndex = -1
      })
    },
    async save () {
      if (!this.vDataTable.editedItem.name || this.vDataTable.editedItem.name === '') {
        this.$swal({type: 'warning', title: this.$t('DocumentHeaderMaint.messagePleaseEnterArticleName')})
        return
      }
      /* if (!this.vDataTable.editedItem.remark || this.vDataTable.editedItem.remark === '') {
        this.$swal({type: 'warning', title: this.$t('DocumentHeaderMaint.messagePleaseEnterArticleDetail')})
        return
      } */
      if (this.vDataTable.editedIndex > -1) {
        await this.updateDocumentHeader(this.vDataTable.editedItem.skey,
          this.vDataTable.editedItem.name,
          this.vDataTable.editedItem.remark,
          this.vDataTable.editedItem.filename,
          this.vDataTable.editedItem.picture_base64,
          this.vDataTable.editedItem.active_flag)
      } else {
        await this.addDocumentHeader(this.vDataTable.editedItem.name,
          this.vDataTable.editedItem.remark,
          this.vDataTable.editedItem.filename,
          this.vDataTable.editedItem.picture_base64,
          this.vDataTable.editedItem.active_flag)
      }
      this.close()
    },
    closeLanguage () {
      this.dialog.dialogLanguage = false
      this.$nextTick(() => {
        this.vDataTable.editedLanguageItem = Object.assign({}, this.vDataTable.defaultLanguageItem)
        this.vDataTable.editedLanguageIndex = -1
      })
    },
    async saveLanguage () {
      if (!this.vDataTable.editedLanguageItem.name || this.vDataTable.editedLanguageItem.name === '') {
        this.$swal({type: 'warning', title: this.$t('DocumentHeaderMaint.messagePleaseEnterArticleName')})
        return
      }
      /* if (!this.vDataTable.editedLanguageItem.remark || this.vDataTable.editedLanguageItem.remark === '') {
        this.$swal({type: 'warning', title: this.$t('DocumentHeaderMaint.messagePleaseEnterArticleDetail')})
        return
      } */
      if (this.vDataTable.editedLanguageIndex > -1) {
        await this.updateDocumentHeaderLanguage(this.vDataTable.editedLanguageItem.skey,
          this.vDataTable.editedLanguageItem.language_skey,
          this.vDataTable.editedLanguageItem.name,
          this.vDataTable.editedLanguageItem.remark)
      } else {
        await this.addDocumentHeaderLanguage(this.vDataTable.editedItem.skey,
          this.vDataTable.editedLanguageItem.language_skey,
          this.vDataTable.editedLanguageItem.name,
          this.vDataTable.editedLanguageItem.remark)
      }
      this.closeLanguage()
    },
    pickFileInsert (image) {
      this.$refs.imageInsert.click()
    },
    handleFileSelectInsert: function (event) {
      var input = event.target
      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          var binaryData = e.target.result
          this.vDataTable.editedItem.filename = input.files[0].name
          this.vDataTable.editedItem.picture_base64 = window.btoa(binaryData)
        }
        reader.readAsBinaryString(input.files[0])
      }
    },
    async copyHeader () {
      console.log(this.findCopyHeader(this.vDataTable.editedItem.skey).name, 'this.findCopyHeader(this.vDataTable.editedItem.skey).name')
      this.vDataTable.editedLanguageItem.name = await this.findCopyHeader(this.vDataTable.editedItem.skey).name
      this.vDataTable.editedLanguageItem.remark = await this.findCopyHeader(this.vDataTable.editedItem.skey).remark
      console.log(this.vDataTable.editedLanguageItem, 'this.vDataTable.editedLanguageItem')
    }
  },
}
</script>
<style scoped>
.style-1 {
  color: rgb(215, 44, 44);
}
.style-2 {
  color: rgb(114,114,67);
}
.sticky-table-footer td {
  font-weight: bold;
  position: sticky;
  bottom: 0;
  background-color: #f8f8f8;
  border-top: thin solid rgba(0,0,0,.12);
}
#vue-editorOrganGrossCustomHeight200 {
  font-family: Arial, Helvetica, sans-serif;
  width: 768px;
  height: 200px;
  caret-color: black;
}
</style>